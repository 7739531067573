import { graphql, PageProps } from "gatsby";
import React from "react";
import Column from "../components/ui/Column";
import GAScripts from "../components/GAScripts";
import Layout from "../components/ui/Layout";
import LinkedText from "../components/ui/LinkedText";
import VerticalButtons from "../components/VerticalButtons";
import emailsData from "../data/emailsData";
import * as classes from "./contact.module.scss";

const ContactPage = () => (
  <Layout includeSocials={false} className={classes["contactPage"]}>
    <Column>
      <div className={classes["layout"]}>
        <div>
          <h2>Defend Educational Freedom</h2>

          <div className={classes["contacts"]}>
            <p>
              Members of the press/media, please contact current students{" "}
              <LinkedText
                external={true}
                newWindow={false}
                to={`mailto:${emailsData.studentsPress}`}
                title={emailsData.studentsPress}
              >
                here
              </LinkedText>
              .
            </p>

            <p>
              Alums and allies{" "}
              <LinkedText
                external={true}
                newWindow={false}
                to={`mailto:${emailsData.alumsAndAlliesPress}`}
                title={emailsData.alumsAndAlliesPress}
              >
                here
              </LinkedText>
              .
            </p>
            <p>
              All general inquiries{" "}
              <LinkedText
                external={true}
                newWindow={false}
                to={`mailto:${emailsData.general}`}
                title={emailsData.general}
              >
                here
              </LinkedText>
              .
            </p>
          </div>
        </div>

        <div className={classes["buttons"]}>
          <VerticalButtons showText={false} />
        </div>
      </div>
    </Column>
  </Layout>
);

export const query = graphql`
  query ContactPage {
    siteBuildMetadata {
      buildTime
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export const Head = ({ data }: PageProps<Queries.AboutPageQuery>) => (
  <>
    <title>{`${data.site?.siteMetadata?.title} - Contact`}</title>
    <meta name="description" content="Save New College" />
    <GAScripts />
  </>
);

export default ContactPage;
